@charset "UTF-8";
@font-face {
  font-family: "asul regular";
  src: url("../font/Fonts/asul-regular.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "FuturaRegular";
  src: url("../font/futura/FuturaLT.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Monsarret";
  src: url("../font/Fonts/Montserrat-VariableFont_wght.ttf") format("woff2");
  font-weight: normal;
  font-style: normal;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Monsarret", sans-serif;
}

body {
  overflow-x: hidden;
}

a:-webkit-any-link {
  text-decoration: none !important;
}

.nav {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 12vh;
  background-color: #fff;
  font-family: "FuturaRegular", sans-serif !important;
  z-index: 999999999;
}

.logo {
  height: auto;
  max-width: 100%;
  display: flex;
  justify-content: center;
}

.logo-image {
  max-height: 10vh;
  max-width: 100%;
}

.nav-links {
  display: flex;
  justify-content: space-around;
  width: 68%;
}

.nav-links li {
  list-style: none;
  margin: 0 5px;
}

.nav-links a {
  color: #000;
  text-decoration: none;
  letter-spacing: 3px;
  font-weight: 500;
  font-size: clamp(1rem, 3vw, 1.2rem) !important;
}

.burger {
  display: none;
}

.burger div {
  width: 25px;
  height: 3px;
  background-color: #018bd8;
  margin: 5px;
  transition: all 0.3s ease;
}

@media screen and (max-width: 1024px) {
  .nav-links {
    width: 80%;
  }
}
@media screen and (max-width: 1100px) {
  body {
    overflow-x: hidden;
  }
  .nav-links {
    position: fixed;
    left: 0;
    right: 0px;
    height: 54vh;
    top: 12vh;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    transform: translateX(-100%);
    transition: transform 0.5s ease-in;
  }
  .burger {
    display: block;
    cursor: pointer;
  }
}
.nav-active {
  transform: translateX(0%);
}

@keyframes navLinkFade {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
.toggle .line1 {
  transform: rotate(-45deg) translate(-5px, 6px);
}

.toggle .line2 {
  opacity: 0;
}

.toggle .line3 {
  transform: rotate(45deg) translate(-5px, -6px);
}

@media screen and (max-width: 400px) {
  .logo img {
    height: 80%;
  }
}
.gallery {
  margin-top: 50px;
}
.gallery .cta-gallery-bg {
  height: 100vh;
  width: 100%;
  background-image: url("../asset/imgs/Pongal Celebrations 7.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.gallery .ril__image,
.gallery .ril__imagePrev,
.gallery .ril__imageNext {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  height: 50vh !important;
  max-width: none;
  -ms-content-zooming: none;
  -ms-user-select: none;
  -ms-touch-select: none;
  touch-action: none;
}
.gallery .gallery-photo {
  width: 100%;
  height: auto;
}
.gallery .gallery-photo .cta-title {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.gallery .gallery-photo .cta-title h1 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #018bd8;
}
.gallery .gallery-photo .cta-filter-option {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  z-index: 1;
  padding: 10px 70px;
}
.gallery .gallery-photo .cta-filter-option button {
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border: none;
  outline: none;
  background-color: transparent;
  color: #000;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  padding: 10px 15px;
}
.gallery .gallery-photo .gallery {
  display: grid;
  justify-items: center;
  padding: 10px 100px;
}
.gallery .gallery-photo .gallery img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.zoomIn {
  animation-name: zoomIn;
  animation-duration: 1s;
  animation-fill-mode: both;
}
@keyframes zoomIn {
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
.share-list {
  position: fixed;
  left: 20px;
  top: 380px;
  z-index: 99999;
  background-color: #fff;
  padding: 5px 2px;
  border-radius: 5px;
}
.share-list ul {
  line-height: 0;
  list-style: none;
}
.share-list ul li {
  line-height: 0;
}
.share-list ul li img {
  height: 30px;
  width: 30px;
}

.casestudy {
  margin-top: 100px;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  /* BlogPage.scss */
}
.casestudy .casestudy-bg {
  height: 50vh;
  width: 100%;
  background-image: url("../asset/imgs/Case Study Main Image.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10%;
}
.casestudy .casestudy-bg h1 {
  font-size: clamp(1rem, 4vw, 2rem);
  color: #000;
  font-weight: 600;
  padding: 3%;
  background: rgba(255, 255, 255, 0.2);
  -webkit-backdrop-filter: blur(30px);
          backdrop-filter: blur(30px);
  text-align: center;
}
.casestudy .blog-main {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 0px 50px;
}
.casestudy .blog-main .blog-list {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 0px 50px;
  background-color: #fff;
  margin-top: 100px;
  border-radius: 10px;
}
.casestudy .blog-main .blog-container {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* Center horizontally */
  align-items: center;
  /* Center vertically */
  min-height: 100vh;
  /* Ensure the container covers the entire viewport height */
}
.casestudy .blog-main .blog-card {
  margin: 10px;
  width: 300px;
  display: inline-block;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
.casestudy .blog-main .blog-card .MuiCardMedia-root {
  height: 250px;
}
.casestudy .blog-main .blog-card .MuiCardContent-root {
  padding: 16px;
}
.casestudy .blog-main .blog-card .MuiCardContent-root .MuiTypography-root {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 8px;
}
.casestudy .blog-main .blog-card .MuiCardContent-root .MuiButton-root {
  background-color: #018bd8;
  color: #fff;
  transition: background-color 0.3s;
  text-align: center;
}
.casestudy body {
  font-size: 16px;
}
@media screen and (max-width: 980px) {
  .casestudy body {
    font-size: 14.4px;
  }
  .casestudy .container {
    width: 90%;
    margin: 0 auto;
  }
}
@media screen and (max-width: 720px) {
  .casestudy body {
    font-size: 12.8px;
  }
  .casestudy .container {
    width: 95%;
  }
  .casestudy .image {
    width: 240px;
  }
}
@media screen and (max-width: 480px) {
  .casestudy body {
    font-size: 11.2px;
  }
  .casestudy .container {
    width: 98%;
  }
  .casestudy .image {
    width: 180px;
  }
  .casestudy .margin-and-padding {
    margin: 5px;
    padding: 10px;
  }
}
@media screen and (max-width: 360px) {
  .casestudy body {
    font-size: 9.6px;
  }
  .casestudy .container {
    width: 100%;
  }
  .casestudy .image {
    width: 120px;
  }
  .casestudy .margin-and-padding {
    margin: 3px;
    padding: 6px;
  }
}

.single-blog {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.single-blog .cta-blog-img {
  width: 100%;
  height: 90vh;
}
.single-blog .cta-blog-img img {
  width: 100%;
}
.single-blog .cta-blog-content {
  width: 80%;
  height: -moz-fit-content;
  height: fit-content;
  margin-top: -150px;
  padding: 10px 25px;
  background-color: #fff;
}
.single-blog .cta-blog-content .cta-story-of-week {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  padding: 10px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.single-blog .cta-blog-content .cta-story-of-week h1 {
  font-size: 50px;
  color: #018bd8;
}
.single-blog .cta-blog-content .cta-blog-title {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  padding: 10px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.single-blog .cta-blog-content .cta-blog-title h3 {
  font-size: 35px;
  color: #018bd8;
  font-weight: 400;
}
.single-blog .cta-blog-content .cta-main-content {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  padding: 20px 0px;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
}
.single-blog .cta-blog-content .cta-main-content p:nth-child(1) {
  font-size: 20px;
  color: #000000;
  font-weight: 400;
  padding: 10px 0px;
}
.single-blog .cta-blog-content .cta-main-content p {
  font-size: 20px;
  color: #808080;
  font-weight: 400;
  padding: 10px 0px;
  text-align: justify;
}

/* Media queries for responsiveness */
@media (max-width: 980px) {
  .single-blog .cta-blog-content {
    width: 90%;
    padding: 10px 20px;
  }
}
@media (max-width: 720px) {
  .single-blog .cta-blog-content {
    width: 95%;
    padding: 10px 15px;
  }
  .single-blog .cta-blog-content .cta-story-of-week h1 {
    font-size: 40px;
  }
  .single-blog .cta-blog-content .cta-blog-title h3 {
    font-size: 30px;
  }
  .single-blog .cta-blog-content .cta-main-content p {
    font-size: 18px;
  }
}
@media (max-width: 480px) {
  .single-blog .cta-blog-content {
    width: 100%;
    padding: 10px 10px;
  }
  .single-blog .cta-blog-content .cta-story-of-week h1 {
    font-size: 35px;
  }
  .single-blog .cta-blog-content .cta-blog-title h3 {
    font-size: 25px;
  }
  .single-blog .cta-blog-content .cta-main-content p {
    font-size: 16px;
  }
}
@media (max-width: 360px) {
  .single-blog .cta-blog-content .cta-story-of-week h1 {
    font-size: 30px;
  }
  .single-blog .cta-blog-content .cta-blog-title h3 {
    font-size: 20px;
  }
  .single-blog .cta-blog-content .cta-main-content p {
    font-size: 14px;
  }
}
/* ProductSection.scss */
.small-chit-funds {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  padding: 5% 0;
}
.small-chit-funds .cta-title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 7%;
  height: -moz-fit-content;
  height: fit-content;
}
.small-chit-funds .cta-title h1 {
  font-size: 3vw;
  color: #018bd8;
  font-weight: 600;
  text-transform: uppercase;
}
.small-chit-funds .cta-all-small-fund {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 0 20%;
  flex-wrap: wrap;
}
.small-chit-funds .cta-all-small-fund .cta-product-details {
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 5% 0;
  border: 1px solid #3f3f3f;
  margin: 0 5%;
}
.small-chit-funds .cta-all-small-fund .cta-product-details .cta-title {
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #3f3f3f;
}
.small-chit-funds .cta-all-small-fund .cta-product-details .cta-title h1 {
  font-size: 2vw;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
}
.small-chit-funds .cta-all-small-fund .cta-product-details .cta-fund-amount {
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.small-chit-funds .cta-all-small-fund .cta-product-details .cta-fund-amount h5 {
  font-size: clamp(0.8rem, 2vw, 2rem);
  color: #018bd8;
  text-align: center;
  padding: 2% 0;
  font-weight: 600;
  text-transform: uppercase;
  border-bottom: 1px solid #000;
  width: 100%;
}
.small-chit-funds .cta-all-small-fund .cta-product-details .cta-fund-amount h6 {
  padding: 2% 5%;
  text-align: start;
  font-size: clamp(0.5rem, 2vw, 2rem);
  color: #018bd8;
  font-weight: 600;
  text-transform: lowercase;
  width: 100%;
}
.small-chit-funds .cta-all-small-fund .cta-product-details .cta-fund-amount p {
  font-size: 3vw;
  width: 100%;
  padding: 2% 5%;
  color: #018bd8;
  font-weight: 600;
  text-transform: capitalize;
  text-align: start;
}
.small-chit-funds .cta-all-small-fund .cta-product-details .cta-small-fund {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  border-radius: 25px;
  padding: 5%;
  margin: 5% 0;
}
.small-chit-funds .cta-all-small-fund .cta-product-details .cta-small-fund .cta-amount {
  display: flex;
  justify-content: center;
  align-items: center;
}
.small-chit-funds .cta-all-small-fund .cta-product-details .cta-small-fund .cta-amount p {
  font-size: 1vw;
  color: #000;
  font-weight: 400;
  text-transform: capitalize;
}
.small-chit-funds .cta-all-small-fund .cta-product-details .cta-small-fund .cta-amount p span {
  font-weight: 600;
}
.small-chit-funds .cta-all-small-fund .cta-product-details .cta-small-fund .cta-pay {
  width: 100%;
  display: flex;
  justify-content: start;
  flex-direction: column;
  gap: 6px;
  align-items: start;
  margin: 2% 0;
}
.small-chit-funds .cta-all-small-fund .cta-product-details .cta-small-fund .cta-pay ul {
  list-style: square;
}
.small-chit-funds .cta-all-small-fund .cta-product-details .cta-small-fund .cta-pay li {
  font-size: 1vw;
  color: #000;
  font-weight: 400;
  text-transform: capitalize;
  padding: 1% 2%;
  border-radius: 50px;
  position: relative;
}
.small-chit-funds .cta-all-small-fund .cta-product-details .cta-view-more {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2% 0;
}
.small-chit-funds .cta-all-small-fund .cta-product-details .cta-view-more .cta-btn {
  font-size: 1vw;
  color: #018bd8;
  font-weight: 600;
  text-transform: capitalize;
  padding: 1% 2%;
  background-color: #fff;
}

@media (max-width: 500px) {
  .small-chit-funds .cta-all-small-fund {
    grid-template-columns: repeat(1, 1fr);
  }
}
.product-bg {
  width: 100%;
  height: 50vh;
  background-image: url("../asset/imgs/rupee-ends-higher-but-oil-companies-dollar-buys-cap-gains.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 5%;
}
.product-bg .cta-product-title {
  background: rgba(255, 255, 255, 0.2);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2% 5%;
  border-radius: 10px;
}
.product-bg h1 {
  font-size: clamp(1.5rem, 4vw, 2.5rem);
  font-weight: 600;
  text-align: center;
  padding-bottom: 3%;
}
.product-bg p {
  font-size: clamp(1rem, 4vw, 2rem);
  color: #000;
  font-weight: 400;
  text-align: center;
  padding: 10px 0px;
}

.Ad-main {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5% 10%;
  background-color: #2532c1;
  color: #fff !important;
}
.Ad-main .cta-wrapper-ad {
  width: 50%;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.Ad-main .cta-wrapper-ad .cta-details {
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.Ad-main .cta-wrapper-ad .cta-details h1 {
  font-size: clamp(1rem, 3vw, 3rem);
  color: #fff;
  font-weight: 600;
  text-align: center;
  padding: 10px 0px;
  line-height: 1.5;
}
.Ad-main .cta-wrapper-ad .cta-details p {
  font-size: clamp(0.8rem, 3vw, 1rem);
  color: #fff;
  font-weight: 400;
  text-align: center;
  padding: 10px 0px;
  text-align: center;
}
.Ad-main .cta-wrapper-ad .cta-play-store {
  height: -moz-fit-content;
  height: fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Ad-main .cta-wrapper-ad .cta-play-store a {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Ad-main .cta-wrapper-ad .cta-play-store a img {
  height: 50%;
  width: 50%;
}
.Ad-main .cta-right-img {
  width: 50%;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Ad-main .cta-right-img img {
  height: 100%;
  width: 100%;
}

@media (max-width: 500px) {
  .Ad-main {
    flex-direction: column;
  }
  .Ad-main .cta-wrapper-ad {
    width: 100%;
    padding: 5% 0%;
  }
  .Ad-main .cta-right-img {
    width: 100%;
    padding: 5% 0%;
  }
}
.footer1 {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  padding: 10% 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #018bd8;
}
.footer1 h1 {
  font-size: 1.5vw;
  color: #fff;
  font-weight: 600;
  text-align: center;
  width: 60%;
  text-align: start;
}
.footer1 a {
  font-size: 1.5vw;
  color: #018bd8;
  font-weight: 600;
  text-align: center;
  text-align: start;
  text-decoration: none;
  padding: 2% 4%;
  background-color: #fff;
  border-radius: 50px;
}

.footer2 {
  height: -moz-fit-content !important;
  height: fit-content !important;
}

.footer3 {
  width: 100%;
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer3 h5 {
  font-size: 1vw;
  color: #000;
  font-weight: 500;
  text-align: center;
  width: 60%;
  text-align: center;
}

/* Styles for the modal popup content */
.content-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  width: 80%;
  max-width: 600px;
  max-height: 80%;
  overflow: auto;
  text-align: center;
}
.content-popup h2 {
  font-size: 24px;
  margin-bottom: 20px;
}
.content-popup button {
  margin-top: 20px;
}

/* Styles for the table inside the modal */
table {
  width: 100%;
  border-collapse: collapse;
  position: relative;
  top: 5%;
}
table tr {
  border-bottom: 1px solid #018bd8;
}
table tr:last-child {
  border-bottom: none;
}
table td {
  padding: 10px;
  border: 1px solid #ccc;
  text-align: center;
}
table td:first-child {
  text-align: left;
}

.cta-popup-product {
  width: 100% !important;
  height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 25%;
  background: rgba(0, 0, 0, 0.8);
  -webkit-backdrop-filter: saturate(180%) blur(10px);
          backdrop-filter: saturate(180%) blur(10px);
}
.cta-popup-product table {
  background-color: #fff;
}
.cta-popup-product table tbody tr th {
  padding: 10px 0px;
  color: #018bd8;
  border: 1px solid #018bd8;
  font-size: clamp(0.8rem, 2vw, 1.5rem);
}
.cta-popup-product table tbody tr td {
  padding: 1% 1.2%;
  color: #000;
  border: 1px solid #018bd8;
  font-size: clamp(0.5rem, 3vw, 0.8rem);
}
.cta-popup-product .cta-btn {
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  justify-content: end;
  align-items: end;
  padding: 1% 2%;
  background-color: #018bd8;
  color: #ffffff;
  font-weight: 600;
  position: absolute;
  top: 2%;
  right: 2%;
}

@media (max-width: 500px) {
  .cta-popup-product {
    padding: 0 5%;
  }
}
.main-table {
  display: flex;
  width: 100%;
}

.slider-main {
  padding: 2% 0;
}
.slider-main h1 {
  color: #000;
  font-size: clamp(1rem, 3vw, 2rem);
  text-align: center;
  padding: 2.5% 0;
}
.slider-main h1 span {
  color: #018bd8;
}
.slider-main p {
  color: #000;
  font-size: clamp(0.5rem, 3vw, 1.5rem);
  text-align: center;
  padding: 3% 0;
}
.slider-main .custom-slider {
  width: 100%;
  padding: 0 10%;
}
.slider-main .custom-box {
  width: 100%;
  height: 100%;
  padding: 1.5%;
}
.slider-main .slick-prev,
.slider-main .slick-next {
  position: absolute;
  line-height: 0;
  top: 90%;
  width: 30px;
  height: 30px;
  display: block;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  border-radius: 50px;
}
.slider-main .slick-next {
  right: -30px;
}
.slider-main .slick-prev {
  left: -30px;
}
.slider-main .slick-next:before {
  content: ">";
  font-size: 1.2em;
  font-weight: 1000;
  padding-left: 12px;
  color: rgb(0, 0, 0);
  z-index: 999;
}
.slider-main .slick-prev:before {
  content: "<";
  font-size: 1.2em;
  font-weight: 1000;
  padding-left: 9px;
  color: rgb(0, 0, 0);
  z-index: 999;
}

.customer-trust {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4% 10%;
  gap: 20px;
}
.customer-trust .customer-title {
  width: 50%;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
}
.customer-trust .customer-title h1 {
  font-size: clamp(1rem, 3vw, 2rem);
  color: #018bd8;
  font-weight: 600;
  text-align: center;
  padding: 2.5% 0;
  text-transform: uppercase;
}
.customer-trust .customer-bond-img {
  width: 50%;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
}
.customer-trust .customer-bond-img img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

.Hiring {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 50px;
}
.Hiring h1 {
  font-weight: 500;
  font-size: xx-large;
  margin: 100px 0px 30px 0px;
}
.Hiring h1 span {
  font-weight: 600;
}
.Hiring h1 span:hover {
  color: black;
}
.Hiring .kopuram {
  font-size: 20px;
  color: #7c7b7b;
  margin-bottom: 35px;
  padding: 0 5%;
  text-align: center;
}

.Main {
  display: flex;
  width: -webkit-fill-available;
}

.jobFilter {
  margin: 20px 0px;
  list-style: none;
  padding: 20px 0px 20px 10px;
}

.jobFilter:active {
  background-color: #018bd8;
  color: white;
}

.brand-dropdown {
  background-color: #018bd8;
  color: white;
  border-radius: 3px;
  padding: 20px 0px 20px 10px;
  margin-top: 0px !important;
  list-style: none;
}

.sidenavbar {
  background-color: #f3f3f3;
  width: 300px;
  margin-right: 10px;
  cursor: pointer;
  border-right: 1px solid #ddd;
}

.sidenavbar table {
  width: 100%;
  border-collapse: collapse;
}

.sidenavbar th,
.sidenavbar td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.job-listings {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
}

.job-card {
  background-color: #fff;
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 20px;
  transition: transform 0.2s, box-shadow 0.2s;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.job-card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
}

.job-icon {
  font-size: xx-large;
}

.job-icon {
  height: 4rem !important;
  width: 4rem !important;
  color: #54595f !important;
}

.description {
  font-weight: 400 !important;
  color: #9e9e9e !important;
}

.btn-wrapper {
  margin: 40px 0px;
}

.btn {
  text-decoration: none;
  background-color: #018bd8;
  color: white;
  padding: 20px;
  border-radius: 25px;
}

.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
  margin-bottom: 7%;
  background-color: white;
}

.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  margin-bottom: 7%;
  background-color: white;
}

.css-7obqij-MuiInputBase-root-MuiInput-root {
  margin-bottom: 7%;
  background-color: white;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  color: transparent !important;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input:focus {
  color: black !important;
}

.MuiDialogTitle-root + .css-ypiqx9-MuiDialogContent-root {
  padding-top: 13px !important;
}

.css-knqc4i-MuiDialogActions-root {
  padding: 0% !important;
}

.css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
  padding-bottom: 0px !important;
}

.css-ypiqx9-MuiDialogContent-root {
  padding-bottom: 5px !important;
}

.section3 {
  background-image: url("../asset/imgs/rupee-ends-higher-but-oil-companies-dollar-buys-cap-gains.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  padding: 10%;
}
.section3 h1 {
  padding: 3%;
  border-radius: 10px;
  text-align: center;
  font-size: xx-large;
  font-weight: 500;
  color: #018bd8;
  font-weight: 900;
  background-color: #fff;
}

.head {
  border: 1px solid transparent;
  background-image: url("../asset/imgs/186597-lichfl.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.head .head-container {
  background: rgba(255, 255, 255, 0.2);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  border-radius: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 70%;
  text-align: center;
  margin: 10%;
}
.head .head-container h2 {
  font-size: clamp(1.5rem, 4vw, 2.5rem);
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 20px;
}
.head h1 {
  color: black;
  font-size: clamp(1rem, 4vw, 2rem);
  font-weight: 500;
  line-height: 1.5;
  margin-bottom: 15px;
}

@media (max-width: 1085px) {
  .head .head-container {
    margin: 250px 20px 60px 20px;
  }
  .section3 h1 {
    padding: 120px 0px 120px 80px;
  }
  .job-listings {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
  }
}
@media (max-width: 945px) {
  .section3 h1 {
    padding: 50px 0px 50px 15px;
    width: -webkit-fill-available;
    font-size: x-large;
  }
  .Hiring h1 {
    font-weight: 500;
    font-size: xx-large;
    margin: 50px 0px 15px 0px;
  }
  .head h1 {
    color: black;
    font-size: 25px;
    font-weight: 500;
    line-height: 35px;
    margin-bottom: 15px;
  }
  .Hiring p {
    font-size: 17px;
    margin-bottom: 10%;
  }
  .job-card h1 {
    margin: 10px 0px 0px 0px !important;
  }
  .job-card p {
    margin: 12px 0px;
  }
}
.cta-hiring-form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cta-form-title {
  font-size: clamp(2rem, 3vw, 3rem);
  color: #018bd8;
}

.cta-know-more-btn {
  background-color: #018bd8;
}

.cta-contact-main {
  margin-top: 6%;
}

.form {
  display: flex;
  align-items: start;
  gap: 15px;
  flex-direction: column;
  padding: 40px 40px;
  height: -moz-fit-content;
  height: fit-content;
  width: 450px;
}
.form input {
  width: 100%;
  height: 35px;
  padding: 7px;
  outline: none;
  border-radius: 5px;
  border: 1px solid rgb(220, 220, 220);
}
.form input:focus {
  border: 2px solid #018bd8;
}
.form textarea {
  max-width: 100%;
  min-width: 100%;
  width: 100%;
  max-height: 500px;
  min-height: 100px;
  padding: 7px;
  outline: none;
  border-radius: 5px;
  border: 1px solid rgb(220, 220, 220);
}
.form textarea:focus {
  border: 2px solid #018bd8;
}
.form label {
  opacity: 0.7;
  margin-top: 1rem;
}
.form input[type=submit] {
  margin-top: 2rem;
  cursor: pointer;
  background: #018bd8;
  color: white;
  border: none;
}
.form select {
  max-width: 100%;
  min-width: 100%;
  width: 100%;
  opacity: 0.7;
  padding: 7px;
  outline: none;
  border-radius: 5px;
  border: 1px solid rgb(220, 220, 220);
}

.ContactMain {
  height: -moz-fit-content;
  height: fit-content;
  padding-bottom: 5%;
  widows: 100%;
  background-image: url("../asset/imgs/Home page 2nd Image.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.ContactMain .item {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ContactMain .item .content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  height: 100vh;
  color: white;
  padding: 0px 20px;
  text-align: center;
}
.ContactMain .item .content h1 {
  font-size: 2.5rem;
  font-weight: 500;
  -webkit-text-stroke: 1px black;
}
.ContactMain .item .ContactUs {
  background-color: #fdf5f5;
  border-radius: 30px;
}

.wrapper {
  height: -moz-fit-content;
  height: fit-content;
  box-sizing: border-box;
  padding: 10% 5%;
}
.wrapper .container {
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 20px;
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 20px 40px 20px;
}
.wrapper .container .cta-card {
  border: 1px solid #018bd8;
  margin: 10px 20px;
  height: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.wrapper .container .cta-card p {
  text-align: center;
  opacity: 0.7;
  width: 334px;
  height: 60px;
  margin: 23px 0px;
}
.wrapper .container .cta-card p span {
  font-weight: 500;
}
.wrapper .container .cta-card .css-i4bv87-MuiSvgIcon-root {
  color: #018bd8;
  font-size: 2.6rem;
}

.cta-hover {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.cta-hover h1 {
  font-size: 60px;
  margin: 50px;
}
.cta-hover i {
  color: #018bd8;
}
.cta-hover i:hover {
  color: black;
}
.cta-hover p {
  font-size: 20px;
  align-items: center;
  margin: -30px;
  opacity: 0.7;
}

.cta-map {
  margin: 50px;
}

.card-container {
  display: flex;
  justify-content: space-evenly;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* 3 columns with equal width */
  gap: 10px;
  padding: 0 5%;
}

.branch-cards {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #018bd8;
  border-radius: 10px;
  padding: 5%;
}
.branch-cards .cta-branch {
  height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-content: space-around;
}
.branch-cards .cta-branch img {
  width: 50%;
  height: 50%;
}
.branch-cards .cta-branch p {
  width: 100%;
  text-align: center;
  justify-content: center;
  opacity: 0.7;
}
.branch-cards .cta-branch h1 {
  font-size: 20px;
}
.branch-cards .cta-branch a {
  text-decoration: none;
}
.branch-cards .cta-branch button {
  background-color: #018bd8;
  color: white;
  padding: 16px 50px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-size: 16px;
}

@media (max-width: 980px) {
  .cta-contact-main {
    margin-top: 10%;
  }
  .wrapper .container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .card-container {
    padding: 5%;
  }
}
@media (max-width: 789px) {
  .cta-contact-main {
    margin-top: 15%;
  }
  .wrapper {
    padding: 0%;
  }
}
.cta-icon {
  margin-top: 38px;
}

@media (max-width: 870px) {
  .ContactMain .item {
    display: block;
  }
  .ContactMain .item .content {
    height: -webkit-fill-available;
    width: -webkit-fill-available;
    padding: 35px 0px;
  }
  .form {
    height: -webkit-fill-available;
    width: -webkit-fill-available;
  }
  .ContactMain .item .ContactUs {
    margin: 0px 30px;
  }
  .card-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 5%;
    gap: 10px;
  }
}
@media (max-width: 600px) {
  .cta-contact-main {
    margin-top: 20%;
  }
  .card-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 10px;
  }
  .cta-hover h1 {
    font-size: 1.8rem;
  }
  .cta-hover p {
    font-size: 15px;
  }
}
@media (max-width: 400px) {
  .cta-contact-main {
    margin-top: 25%;
  }
  .ContactMain .item .ContactUs {
    margin-top: -140px;
  }
  .ContactMain .item .content h1 {
    font-size: 2rem;
    margin-bottom: 140px;
  }
  .wrapper .container .cta-card p {
    width: -webkit-fill-available;
  }
  .cta-hover p {
    font-size: 16px;
  }
}
.cta-main-home .cta-home-top-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.cta-main-home .wrapper1 {
  display: flex;
  background-color: #018bd8;
  border-bottom: 2px;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 5%;
}
.cta-main-home .wrapper1 .container1 {
  padding-left: 10%;
  color: #fff;
}
.cta-main-home .wrapper1 .container1 h1 {
  font-size: 55px;
  font-weight: 600;
  padding-bottom: 5px;
}
.cta-main-home .wrapper1 .container1 p {
  line-height: 20px;
  padding: 5% 0;
}
.cta-main-home .wrapper1 .container1 button {
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border: none;
  color: #018bd8;
  padding: 2% 10%;
  background-color: #fff;
}
.cta-main-home .wrapper1 .container1 .store {
  display: flex;
  margin-top: 60px;
  width: 100%;
}
.cta-main-home .wrapper1 .container1 .store .playstore img {
  width: 280px;
  height: 90px;
}
.cta-main-home .wrapper1 .container1 .store .Appstore {
  padding-left: 2%;
}
.cta-main-home .wrapper1 .container1 .store .Appstore img {
  width: 280px;
  height: 90px;
}
.cta-main-home .wrapper1 .container2 {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cta-main-home .wrapper1 .container2 img {
  width: 100%;
  height: 100%;
}
.cta-main-home .icons {
  display: grid;
  grid-template-columns: 16% 16% 16% 16% 16% 16%;
  margin-left: 15%;
  margin-right: 15%;
  box-shadow: -3px 10px 11px rgba(191, 206, 221, 0.2);
  background-color: #fff;
  border-radius: 20px;
  margin-top: -60px;
  padding: 2%;
}
.cta-main-home .icons img {
  padding-top: 10px;
  width: 100px;
  height: 100px;
}
.cta-main-home .icons p {
  padding-bottom: 30px;
}
.cta-main-home .py-14 {
  background-color: #018bd8;
}
.cta-main-home .py-14 button {
  color: white;
  top: 95px;
  margin-left: -11px;
}
.cta-main-home .py-14 button .left-4 {
  left: 179px;
}
.cta-main-home .py-14 .right-4 {
  left: 179px;
}

.readmore {
  padding-left: 5%;
  padding-right: 5%;
  text-align: justify;
  line-height: 2.5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
}
.readmore .ReadMoreContent {
  border: 1px solid #ccc;
  padding: 10px;
  max-width: 600px;
  background-color: #f9f9f9;
}
.readmore a:-webkit-any-link {
  text-decoration: none !important;
  color: #fff;
  background-color: #018bd8;
  width: 170px !important;
  height: 60px !important;
  padding: 5px;
  margin: 10px 0;
}
.readmore button {
  width: 200px;
  height: 20px;
  padding: 5px;
  border-radius: 10px;
  font-size: 22px;
  margin-bottom: 15px;
  margin-bottom: 5%;
}
.readmore .ReadMoreContent.expanded {
  max-height: none;
}

.video h1 {
  font-size: 45px;
  font-weight: 500;
  color: #018bd8;
  padding-bottom: 25px;
}
.video carousel {
  border: 2px solid black;
}
.video .video-slide {
  margin-left: 25% !important;
  margin-right: 20%;
  width: 800px !important;
  height: 400px;
}

.product {
  background-color: #F9F9F9;
}
.product .container5 {
  padding: 5%;
  text-align: center;
}
.product .container5 h1 {
  font-size: 55px;
  font-weight: 400;
}
.product .container5 span {
  display: block;
  color: #018bd8;
}
.product .container5 span:hover {
  color: #000;
}
.product .container5 p {
  font-size: 20px;
  color: grey;
}
.product .container6 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  padding: 5%;
  margin-left: 0%;
}
.product .container6 li {
  font-size: 25px;
  color: rgb(127, 124, 124);
  display: flex;
  justify-content: start;
  align-items: center;
}
.product .container6 h2 {
  text-align: center;
  font-size: 30px;
  font-weight: 400;
  color: #018bd8;
}
.product .container6 .wrapper2 {
  width: 50%;
  line-height: 80px;
  text-align: justify;
}
.product .container6 .wrapper2 ul {
  list-style-type: none;
}
.product .container6 .wrapper2 ul .custom-icon-color {
  color: #018bd8;
  width: 50px;
  height: 50px;
  margin-bottom: 3%;
}
.product .container6 .wrapper3 {
  width: 50%;
  line-height: 80px;
  text-align: justify;
}
.product .container6 .wrapper3 ul {
  list-style-type: none;
}
.product .container6 .wrapper3 ul .custom-icon-color {
  color: #018bd8;
  padding-right: 10px !important;
  width: 50px;
  height: 50px;
  margin-bottom: -3%;
}
.product .container7 {
  padding-bottom: 5%;
  padding-top: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.product .container7 a {
  background-color: #018bd8;
  color: #fff;
}
.product .container7 a button {
  padding: 5px;
  border-radius: 10px;
  font-size: 22px;
}

.content {
  background-color: #F9F9F9;
}
.content .container8 {
  padding: 5%;
  text-align: center;
}
.content .container8 h1 {
  color: #018bd8;
  font-size: 55px;
  font-weight: 400;
  padding-bottom: 16px;
}
.content .container8 p {
  line-height: 42px;
  font-size: 20px;
  color: grey;
  width: 100%;
}
.content .container8 span {
  display: block;
}
.content .wrapper4 {
  display: flex;
  justify-content: space-evenly;
}
.content .wrapper4 .container9 {
  width: 50%;
  height: 50%;
}
.content .wrapper4 .container9 img {
  width: 100%;
  height: 100%;
}
.content .wrapper4 .container10 .cta-step-main {
  padding: 5% 0;
}
.content .wrapper4 .container10 h1 {
  color: #018bd8;
  text-align: start;
}
.content .wrapper4 .container10 h2 {
  text-align: start;
}
.content .wrapper4 .container10 ul {
  margin-top: 15%;
  padding-left: 15%;
}
.content .wrapper4 .container10 ul li {
  text-align: start;
  color: grey;
}
.content .wrapper4 .container10 .container7 {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5% 0;
}
.content .wrapper4 .container10 .container7 a button {
  width: 150px;
  height: 50px;
  padding: 5px;
  border-radius: 10px;
  background-color: white;
  color: #018bd8;
  font-size: 18px;
  transition: background 0.3s;
  margin-left: -37%;
}
.casestudy {
  background-color: #F9F9F9;
  padding-top: 0;
}
.casestudy img {
  width: 100%;
  height: 100%;
}
.casestudy .container11 {
  text-align: center;
  width: 100%;
}
.casestudy .container11 h1 {
  font-size: 50px;
  font-weight: 500;
}
.casestudy .container11 span {
  display: block;
  color: #018bd8;
  font-style: italic;
}
.casestudy .container11 p {
  font-size: 20px;
  color: grey;
  margin-left: 5% !important;
  margin-top: 3%;
}
.casestudy .container11 img {
  width: 100px;
  height: 100px;
  margin-bottom: 40px;
}
.casestudy .container11 img {
  overflow-clip-margin: content-box;
  overflow: clip;
}
.casestudy .carousel .slide {
  width: 250px;
  height: 250px;
}
.casestudy .carousel .slide img {
  width: 400px;
  height: 500px;
}
.casestudy .slide {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  border-radius: 8px;
}
.casestudy .carousel .thumbs-wrapper {
  display: none;
}
.casestudy .carousel .slider-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.casestudy .carousel .control-arrow {
  font-size: 24px;
}
.casestudy .carousel .control-prev.control-arrow::before {
  content: "←";
}
.casestudy .carousel .control-next.control-arrow::before {
  content: "→";
}
.casestudy .carousel.carousel-slider {
  position: relative;
  margin: 0;
  padding-bottom: 2%;
}
.casestudy .caption {
  position: absolute;
  bottom: 20px;
  /* Adjust the position as needed */
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  padding: 5px 10px;
  font-size: 30px;
  border-radius: 5px;
}

.carousel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 300px;
}
.carousel-container .carousel-slide {
  display: none;
  text-align: center;
  position: relative;
}
.carousel-container .carousel-slide.active {
  display: block;
}
.carousel-container .carousel-slide img {
  max-width: 100%;
  max-height: 100%;
}
.carousel-container .carousel-slide .carousel-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 10px;
}
.carousel-container .prev-button,
.carousel-container .next-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

@media only screen and (max-width: 980px) {
  .wrapper1 .container1 .store img {
    width: 200px !important;
    height: 60px !important;
  }
  .secure {
    display: flex;
    flex-direction: column;
  }
  .secure .container3 {
    width: 100%;
  }
  .secure .container3 p {
    padding: 5% 0;
  }
  .secure .container3 h2 {
    padding: 5%;
  }
  .secure .container3 span {
    display: block;
  }
  .video .video-slide {
    margin-left: 15% !important;
    width: 500px;
  }
  .product .container6 .wrapper2 {
    margin-left: 5% !important;
    line-height: 30px !important;
  }
  .product .container6 .wrapper3 {
    margin-left: 5% !important;
    line-height: 30px !important;
  }
  .product .container6 li {
    font-size: 20px;
    color: rgb(127, 124, 124);
  }
}
@media only screen and (max-width: 720px) {
  .wrapper1 {
    flex-direction: column-reverse;
  }
  .wrapper1 .container1 {
    padding-top: 0%;
    text-align: center;
  }
  .wrapper1 .container1 h1 {
    font-size: 25px;
    padding-top: 15px;
  }
  .icons {
    display: grid !important;
    grid-template-columns: 50% 50% !important;
    margin-top: 50px;
    box-shadow: none;
  }
  .icons .user {
    height: 250px;
  }
  .icons .trophy {
    height: 250px;
  }
  .icons .gift {
    height: 250px;
  }
  .icons .heart {
    height: 250px;
  }
  .icons .location {
    height: 250px;
  }
  .icons .camera {
    height: 250px;
  }
  .wrapper1 .container1 .store img {
    width: 330px !important;
    height: 87px !important;
    margin-left: 0%;
  }
  .icons {
    display: grid;
    grid-template-columns: 50% 50%;
    margin-top: 50px;
    box-shadow: none;
  }
  .icons .user {
    height: 250px;
  }
  .icons .trophy {
    height: 250px;
  }
  .icons .gift {
    height: 250px;
  }
  .icons .heart {
    height: 250px;
  }
  .icons .location {
    height: 250px;
  }
  .icons .camera {
    height: 250px;
  }
  .secure {
    display: flex;
    flex-direction: column-reverse;
  }
  .secure .container3 h1 {
    font-size: 20px;
  }
  .secure .container3 h1 span {
    display: inline;
  }
  .secure .container4 {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .video h1 {
    font-size: 30px;
  }
  .video .video-slide {
    width: 500px !important;
    height: 300px;
    margin-bottom: 15%;
  }
  .content .container8 h1 {
    font-size: 30px;
    font-weight: 500;
    padding-top: 10%;
  }
  .content .wrapper4 {
    display: grid;
  }
  .content .wrapper4 .container9 img {
    display: none;
  }
  .content .wrapper4 .container10 {
    margin-top: -10% !important;
    display: flex;
    justify-content: center;
    align-items: start;
    width: 100%;
    flex-direction: column;
  }
  .content .wrapper4 .container10 h1 {
    margin-top: 10%;
    font-size: 15px;
    padding-left: 5%;
  }
  .content .wrapper4 .container10 h2 {
    padding-left: 5%;
  }
  .content .wrapper4 .container10 ul {
    margin-top: 8%;
  }
  .product .container5 h1 {
    font-size: 30px;
    font-weight: 500;
  }
  .product .container5 h1 span {
    display: inline;
  }
  .product .container5 p {
    width: 100%;
    font-size: 15px;
    font-weight: 400;
    padding-top: 5%;
    padding-left: 5%;
    padding-right: 5%;
    text-align: center;
  }
  .product .container6 li {
    display: flex;
    flex-direction: column;
    font-size: 18px;
    text-align: center;
  }
}
@media only screen and (max-width: 480px) {
  .wrapper1 .container1 .store img {
    width: 170px !important;
  }
  .icons {
    display: grid;
    grid-template-columns: 100% !important;
  }
  .product .container5 h1 {
    font-size: 28px;
    font-weight: 500;
  }
  .product .container5 p {
    width: 100%;
    padding-left: 6% !important;
  }
  .product .container6 .wrapper2 li {
    line-height: normal;
  }
  .product .container6 .wrapper2 .li1 {
    margin-top: 8%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .product .container6 .wrapper3 li {
    line-height: normal;
  }
  .product .container6 .wrapper3 .li1 {
    margin-top: 8%;
  }
  .product .product .container6 .wrapper2 {
    margin-left: 1% !important;
  }
  .product .product .container6 .wrapper3 {
    margin-left: 1% !important;
  }
  .content .container8 h1 {
    font-size: 28px;
    font-weight: 500;
    padding-top: 10%;
  }
  .content .wrapper4 .container9 img {
    margin-left: 50% !important;
  }
  .secure .container4 iframe {
    width: 60%;
    height: 70% !important;
  }
  .video .video-slide {
    margin-left: -1% !important;
    width: 500px;
  }
}
.casestudy .container11 h1 {
  font-size: 30px;
  font-weight: 500;
  padding: 0 5%;
}
.casestudy .container11 p {
  font-size: 20px;
  text-align: center !important;
  color: grey;
  margin-top: 3%;
  text-align: justify;
  padding-bottom: 5%;
}
.casestudy .container11 .carousel {
  margin-top: 5%;
}

@media only screen and (max-width: 360px) {
  .wrapper1 .container1 .store {
    padding-left: 2% !important;
    display: flex;
    flex-direction: column;
  }
  .wrapper1 .container1 .store img {
    width: 170px !important;
  }
  .icons {
    display: grid;
    grid-template-columns: 100% !important;
  }
  .content .container8 h1 {
    padding-top: 10%;
    font-size: 28px;
    font-weight: 500;
  }
  .content .container8 p {
    font-size: 15px;
    text-align: center;
  }
  .content .container9 img {
    margin-left: 55% !important;
  }
  .video .video-slide {
    margin-left: -5% !important;
    width: 400px !important;
  }
  .product .container6 {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
  .product .container6 .wrapper2 {
    margin-left: 5% !important;
  }
  .product .container6 .wrapper3 {
    margin-left: 5% !important;
  }
}
.carousel-root {
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel.carousel-slider {
  width: 73% !important;
}

.cta-no-image {
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider-container {
  width: 100%;
  position: relative;
  margin: 50px 0;
}

.slide {
  width: 100%;
  display: none;
}

.slide-image {
  width: 100%;
  height: 60vh;
  -o-object-fit: cover;
     object-fit: cover;
  filter: brightness(0.6);
}

.slide-content {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  text-align: center;
  padding: 1rem;
}

.slide-title {
  width: 100%;
  max-width: 50rem;
  color: white;
  font-size: clamp(2rem, 4vw, 4rem) !important;
  font-weight: 500 !important;
  text-transform: capitalize;
}

.slide-desc {
  width: 100%;
  max-width: 50rem;
  color: lightgray;
  font-size: clamp(1rem, 3vw, 2rem) !important;
  font-weight: 300;
}

.slide-btn {
  color: black;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-size: 1.2rem;
  font-weight: 500;
  margin-top: 1rem;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  text-transform: capitalize;
  transition: ease 0.3s;
}

.slide-btn:hover {
  gap: 1rem;
}

.slide-number-container {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  z-index: 5;
  font-size: 1.5rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  color: rgba(211, 211, 211, 0.788);
  letter-spacing: 0.2rem;
}

.slide-number-container hr {
  width: 3rem;
  transform: rotate(130deg);
  border-color: rgba(211, 211, 211, 0.788);
}

.slider-nav {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.slider-nav-btn {
  cursor: pointer;
  background-color: rgba(211, 211, 211, 0.226);
  color: white;
  font-size: 1.5rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ease 0.3s;
}

.slider-nav-btn:hover {
  background-color: white;
  color: black;
}

.dot-container {
  position: absolute;
  bottom: 1rem;
  left: 0;
  z-index: 4;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}
.dot-container .dot {
  cursor: pointer;
  height: 1rem;
  width: 1rem;
  background-color: rgba(211, 211, 211, 0.226);
  border-radius: 50%;
  display: inline-block;
  transition: ease 0.3s;
}
.dot-container .active,
.dot-container .dot:hover {
  background-color: white;
}

.fade {
  animation-name: fade;
  animation-duration: 1s;
}

@keyframes fade {
  from {
    opacity: 0.8;
  }
  to {
    opacity: 1;
  }
}
/* BlogPage.css */
.blog-card {
  position: relative;
}

.clickable-image {
  cursor: pointer;
}

.overlay-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 16px;
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.cta-view-more-product {
  background-color: #0c52a6 !important;
  color: white !important;
  border-radius: 25px !important;
}

.Imgslider {
  height: 400px;
  /* Adjust the height as needed */
}

.py-14 .bg-gray-100 button.text-4xl.text-gray-500.focus\:outline-none.absolute.top-1\/2.right-4.transform.-translate-y-1\/2 {
  left: 174px;
  margin-left: 31px;
}

.slide {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@media screen and (max-width: 650px) {
  #slider,
  .wrap,
  .slide-content {
    height: 50vh;
  }
}
@media screen and (max-width: 420px) {
  #slider,
  .wrap,
  .slide-content {
    height: 30vh;
  }
}
.max-h-full {
  max-height: 100vw !important;
}

#slider,
.wrap,
.slide-content {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.wrap {
  position: relative;
}

.slide {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.slide-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.slide-content span {
  font-size: 5rem;
  color: #fff;
}

.slide-content img {
  width: 100%;
  height: 100%;
  height: -moz-fit-content;
  height: fit-content;
  -o-object-fit: contain;
     object-fit: contain;
}

.bg-gray-100 {
  background-color: transparent !important;
}

.cta-footer-list-main {
  text-align: center !important;
}

.py-14 .bg-gray-100 button.text-4xl.text-gray-500.focus\:outline-none.absolute.top-1\/2.right-4.transform.-translate-y-1\/2 {
  left: 174px;
  margin-left: 31px;
}

.slide {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@media screen and (max-width: 650px) {
  #slider,
  .wrap,
  .slide-content {
    height: 50vh;
  }
}
@media screen and (max-width: 420px) {
  #slider,
  .wrap,
  .slide-content {
    height: 30vh;
  }
}
.max-h-full {
  max-height: 100vw !important;
}

#slider,
.wrap,
.slide-content {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 60vh;
  overflow: hidden;
}

.wrap {
  position: relative;
}

.slide {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.slide-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.slide-content span {
  font-size: 5rem;
  color: #fff;
}

.slide-content img {
  width: 100%;
  height: 100%;
  height: -moz-fit-content;
  height: fit-content;
  -o-object-fit: contain;
     object-fit: contain;
}

.bg-gray-100 {
  background-color: transparent !important;
}

.cta-footer-list-main {
  text-align: center !important;
}

.cta-footer-logo {
  width: 30% !important;
}

/* Your existing styles */
/* Style for the drop-down menu */
.dropdown {
  display: none;
  /* Initially hide the dropdown */
  position: absolute;
  top: 50%;
  right: 0 !important;
  min-width: 160px;
  z-index: 1000;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Show the dropdown when its parent is hovered */
.group:hover .dropdown {
  display: block;
}

/* Additional styles for dropdown items */
.dropdown li {
  padding: 8px 12px;
  list-style: none;
  cursor: pointer;
}

.dropdown li:hover {
  background-color: #d6d6d6;
}

.nav-links li.active a {
  color: #018bd8;
  /* Set your desired active color */
}

.menu-link {
  font-size: 0.8rem, 4vw, 1.2rem !important;
}

@media screen and (max-width: 490px) {
  .casestudy .blog-main .blog-list {
    padding: 0;
  }
}
.cta-list-top {
  margin-top: -100px;
}

.cta-four-card {
  margin-top: -50px;
}

.cta-h-half {
  height: 50vh !important;
}

@media screen and (max-width: 1000px) {
  .cta-sub-list {
    flex-wrap: wrap;
    justify-content: center;
  }
  .cta-list-top {
    margin-top: -10px;
  }
}
@media screen and (max-width: 500px) {
  .gallery .gallery-photo .gallery {
    padding: 10px;
  }
}
.cta-home-slider-img {
  width: 50% !important;
  height: 50% !important;
  -o-object-fit: contain;
     object-fit: contain;
}

.testimonial-item {
  display: none;
}

.testimonial-item.visible {
  display: block;
}

.cta-know-more {
  color: #fff !important;
}

.cta-footer-text {
  text-align: center !important;
}

.cta-text-center {
  text-align: center !important;
  font-size: 20px !important;
}

.cta-card-height {
  height: -moz-fit-content;
  height: fit-content;
}

.cta-move-top {
  margin-top: -5%;
  margin-bottom: -5%;
}

.cta-job-button {
  height: 50px;
  width: 100px;
  z-index: 99;
  position: absolute;
  margin-top: -36px;
}

.cta-job-card {
  margin: 0 3%;
}

.cta-faq-margin {
  padding: 5% 0 !important;
}

.cta-half-effect {
  width: 50%;
  background: rgba(255, 255, 255, 0.2);
  -webkit-backdrop-filter: blur(100px);
          backdrop-filter: blur(100px);
  padding: 5%;
}

@media screen and (max-width: 745px) {
  .cta-half-effect {
    width: 100%;
  }
}
.custom-icon-color {
  color: #018bd8;
  width: 50px;
  height: 50px;
  margin-bottom: 3%;
}

.caption {
  text-align: center;
  font-size: clamp(1rem, 4vw, 2rem);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999;
}

.modal-content {
  position: relative;
  max-width: 800px;
  max-height: 800px;
  width: 100%;
  height: 100%;
}

.modal-content img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

.modal-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  padding: 20px;
  cursor: pointer;
  color: #fff;
  font-size: 24px;
}

.close-btn {
  left: 90%;
  /* Adjust as needed */
  top: 20%;
}

.prev-btn {
  left: 10px;
}

.next-btn {
  right: 10px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .modal-btn {
    font-size: 18px;
    padding: 15px;
  }
}
@media (max-width: 576px) {
  .modal-btn {
    font-size: 16px;
    padding: 12px;
  }
  .close-btn {
    left: 80%;
    /* Adjust as needed */
  }
}
/* YourStylesheet.css */
.filter-options {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 20px;
}

.filter-options button {
  background-color: transparent;
  /* Remove background color */
  color: #0c52a6;
  border: none;
  padding: 10px 20px;
  margin: 0 5px;
  font-size: clamp(1.2rem, 4vw, 0.8rem);
  font-weight: 700;
  cursor: pointer;
  transition: color 0.3s;
  /* Add color transition */
}

.filter-options button:hover {
  color: #0c52a6;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .filter-options {
    flex-direction: column;
    align-items: center;
  }
  .filter-options button {
    margin: 5px 0;
  }
}
@media (max-width: 576px) {
  .filter-options button {
    padding: 8px 16px;
    font-size: 14px;
  }
}
.Doorstep-img {
  background-image: url("../asset/imgs/NewImg (10).jpg");
}

.Monthly-img {
  background-image: url("../asset/imgs/NewImg (16).jpg");
}

.Online-img {
  background-image: url("../asset/imgs/NewImg (14).jpg");
}

.Diverse-img {
  background-image: url("../asset/imgs/Case Study Insurance_.jpg");
}

.carousel-img {
  width: 100% !important;
  -o-object-fit: contain;
     object-fit: contain;
}

.cta-home-img {
  width: 100wh;
  text-align: center;
}

/* Add this to your styles.css or a separate stylesheet */
.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 1000ms ease-in-out;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 1000ms ease-in-out;
}

.half-img {
  height: 50vh;
}

.cta-dropdown-option {
  width: -moz-max-content;
  width: max-content;
}

.cta-team-top {
  margin-top: 10%;
}

@media (max-width: 950px) {
  .cta-team-top {
    margin-top: 25%;
  }
}
/* Add this to your existing CSS file or component */
@keyframes zoomIn {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
.dropdown {
  animation: zoomIn 0.3s ease-out;
}

.cta-chitact {
  background-image: url("../asset/imgs/gallery/NewImg (15).jpg");
  width: 100%;
}

.cta-act-points {
  list-style: circle;
}

.ReactModal__Overlay {
  z-index: 999999999;
}

.sidenavbar .jobFilter.active::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  background-color: #000000; /* Adjust the color of the vertical line */
}

.input-box-file {
  position: static;
  margin-bottom: 10px;
}

.special-class {
  letter-spacing: 2px !important;
}

.bet-color-change {
  color: #fff !important;
}

.custom-class-for-add {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 100% !important;
  padding: 10% !important;
  margin: 0 !important;
  text-align: center !important;
}
.custom-class-for-add h1 {
  text-align: center;
}/*# sourceMappingURL=style.css.map */